<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.template_email.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.tipo_calibracao.titulos.novo')"
      :sem-botao-ok="true"
      :sem-filtro="!buscarPermissao('EmailTemplate', 'Listar')"
    />

    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="tabela.selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      :mostrar-acoes="true"
      :quantidade-itens="tabela.quantidadeItens"
      :mostrar-seletor="false"
      :mostra-header="false"
      sem-paginacao
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao text color="secondary">
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item
              v-if="buscarPermissao('EmailTemplate', 'Editar')"
              text
              color="secondary"
              @click="abrirEditar(slotProps.data)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
          </div>
          <div>
            <dropdown-padrao-item
              text
              color="secondary"
              :disabled="podeVisualizar(slotProps.data)"
              @click="abrirVisualizar(slotProps.data)"
            >
              {{ $t('geral.botoes.visualizar') }}
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import helpers from '@common/utils/helpers';
import { TemplateEmailService } from '@/common/services';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';

export default {
  components: {
    CabecalhoPagina,
  },

  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'tipoEmail',
            text: this.$t('modulos.template_email.tabela.tipo_email'),
            sortable: true,
            formatter: (v) => {
              return this.opcoes.templateEmail.find((el) => el.value === v)
                ?.text;
            },
          },
          {
            value: 'assunto',
            text: this.$t('modulos.template_email.tabela.assunto'),
            sortable: true,
          },
          {
            value: 'flagRetornoEmailUsuarioLogado',
            text: this.$t('modulos.template_email.tabela.retorno_usuario'),
            sortable: true,
            formatter: (v) => {
              return helpers.BoleanoEnum.find((el) => el.value === v)?.text;
            },
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      opcoes: {
        templateEmail: [],
      },
    };
  },

  mounted() {
    this.buscarTemplateEnum();
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.template_email.titulos.listagem')
    );
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'EmailTemplate', 'Listar');
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    listarRegistros(
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      TemplateEmailService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data;
          this.tabela.quantidadeItens = res.data.length;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarTemplateEnum: function () {
      EnumeradorService.buscar('EnumTipoEmail').then((res) => {
        this.opcoes.templateEmail = res;
      });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'modelo-email-editar',
        params: { tipoEmail: item.tipoEmail },
      });
    },
    abrirVisualizar: function (item) {
      this.$router.push({
        name: 'modelo-email-editar-visualizar',
        params: { tipoEmail: item.tipoEmail },
      });
    },
    podeVisualizar: function (item) {
      return !!item.nomeExibicao;
    },
  },
};
</script>
